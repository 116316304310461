/**
 * @ComponentFor AdvanceImageBlockViewModel
 */

import React from 'react';
import AdvanceImageBlockType from './AdvanceImageBlockViewModel.type';
import Image from '../../Image';
import { styled, StyledProps } from '@glitz/react';
import { epsilon, gamma, alpha, zeta, huge, pixelsToUnit } from 'Shared/Style';
import { Style } from '@glitz/type';
import Link from 'Shared/Link';
import { minMediumMediaQuery } from 'Shared/Style';
import { pseudo } from '@glitz/core';
import { isIE } from '@avensia/scope';

type RequieredProps = AdvanceImageBlockType;

type Props = { redirectLink?: string } & RequieredProps & StyledProps;

type ButtonPropType = {
  value: string;
  link: string;
};

const Wrapper = styled.div({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  overflow: 'hidden',
  maxHeight: '45rem',
  paddingBottom: '2rem',
  marginTop: '1rem',
  [minMediumMediaQuery]: {
    maxHeight: '45rem',
    margin: { x: 0, y: '2rem' },
  },
});

const ContentWrapper = styled.div({
  textAlign: 'center',
  maxWidth: pixelsToUnit(870),
  width: '100%',
  paddingBottom: '1rem',
});

const ButtonWrapper = styled.div({
  paddingTop: pixelsToUnit(20),
  flex: {
    direction: 'column',
  },
  alignItems: 'center',
  [minMediumMediaQuery]: {
    paddingTop: pixelsToUnit(30),
  },
});

const SingleRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: {
    direction: 'column',
  },
  [minMediumMediaQuery]: {
    flex: {
      direction: 'row',
    },
  },
});

const TopRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: pixelsToUnit(20),
  flex: {
    direction: 'column',
  },
  [minMediumMediaQuery]: {
    flex: {
      direction: 'row',
    },
  },
});

const BottomRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: {
    direction: 'column',
  },
  [minMediumMediaQuery]: {
    flex: {
      direction: 'row',
    },
  },
});

const ButtonLink = styled(Link, {
  display: 'inline-block',
  borderRadius: pixelsToUnit(5),
  font: {
    family: '"Nunito", "PT Sans", "sans-serif"',
    size: zeta,
    weight: 600,
  },
  cursor: 'pointer',
  letterSpacing: pixelsToUnit(0.8),
  padding: {
    y: pixelsToUnit(14),
    x: huge,
  },
  border: {
    xy: {
      style: 'none',
    },
  },
  textTransform: 'uppercase',
  ':hover': {
    opacity: 0.85,
  },
  ...pseudo(':not(:first-child)', {
    marginTop: pixelsToUnit(20),
  }),
  [minMediumMediaQuery]: {
    lineHeight: pixelsToUnit(20),
    font: {
      size: epsilon,
    },
    ...pseudo(':not(:first-child)', {
      marginTop: pixelsToUnit(0),
    }),
  },
});

const Heading = styled.h1({
  font: {
    family: '"Nunito", "PT Sans", "sans-serif"',
    size: gamma,
    weight: 600,
  },
  lineHeight: pixelsToUnit(32),
  [minMediumMediaQuery]: {
    font: {
      size: alpha,
    },
    lineHeight: pixelsToUnit(48),
    margin: {
      x: pixelsToUnit(20),
      y: pixelsToUnit(0),
    },
  },
  margin: {
    x: pixelsToUnit(10),
    y: pixelsToUnit(0),
  },
});

export default (props: Props & StyledProps) => {
  const { heading, buttonLinkCollection } = props;

  const maxNumberOfButtons = 8;

  const textStyling: Style = {
    color: !!props.headingTextColor ? props.headingTextColor : 'black',
  };

  const backgroundStyling: Style = {
    paddingTop: '1rem',
    backgroundColor: !!props.backgroundColor ? props.backgroundColor : 'black',
  };

  const buttonStyling: Style = {
    width: '80%',
    lineHeight: pixelsToUnit(10),
    color: !!props.buttonTextColor ? props.buttonTextColor : 'black',
    backgroundColor: !!props.buttonBackgroundColor ? props.buttonBackgroundColor : 'white',
    margin: { x: pixelsToUnit(6) },

    [minMediumMediaQuery]: {
      display: 'flex',
      margin: { x: pixelsToUnit(10) },
      alignItems: 'center',
      justifyContent: 'center',
      color: !!props.buttonTextColor ? props.buttonTextColor : 'black',
      backgroundColor: !!props.buttonBackgroundColor ? props.buttonBackgroundColor : 'white',
      width: pixelsToUnit(230),
      height: pixelsToUnit(60),
    },
  };

  const halfLength = Math.ceil(buttonLinkCollection.length / 2);

  function Button(props: ButtonPropType) {
    return (
      <ButtonLink to={props.link} css={buttonStyling}>
        {props.value}
      </ButtonLink>
    );
  }

  const imageComponent = (
    <styled.Div css={{ flexGrow: 1 }}>
      <Image
        css={{
          objectFit: 'cover',
          display: 'block',
          height: '100%',
          width: '100%',
          minHeight: '30rem',
          backgroundColor: props.backgroundColorTransparent ? 'transparent' : 'white',
          [minMediumMediaQuery]: {
            width: '100%',
          },
        }}
        src={props.imageUrl && props.imageUrl.url}
        alt={props.altText}
        lazy
      />
    </styled.Div>
  );

  const emptyImageComponent = <styled.Div></styled.Div>;

  return (
    <Wrapper css={props.isBackgroundColorSelected && backgroundStyling}>
      {!props.isBackgroundColorSelected && !!props.imageUrl ? imageComponent : emptyImageComponent}

      <ContentWrapper
        css={
          isIE()
            ? props.isBackgroundColorSelected
              ? { left: '50%', top: '50%', transform: 'translate(-50%, -50%)', position: 'relative' }
              : { left: '50%', top: '50%', transform: 'translate(-50%, -50%)', position: 'absolute' }
            : props.isBackgroundColorSelected
            ? { position: 'relative' }
            : { position: 'absolute' }
        }
      >
        <Heading css={textStyling}>{heading}</Heading>
        {buttonLinkCollection.length > 0 && (
          <ButtonWrapper
            css={
              buttonLinkCollection.length < maxNumberOfButtons
                ? { justifyContent: 'space-around' }
                : { justifyContent: 'space-between' }
            }
          >
            {buttonLinkCollection.length > 3 ? (
              <div>
                <TopRow>
                  {!!buttonLinkCollection &&
                    buttonLinkCollection
                      .slice(0, halfLength)
                      .map((button, idx) => (
                        <Button key={idx} link={buttonLinkCollection[idx].url} value={button.title} />
                      ))}
                </TopRow>
                <BottomRow>
                  {!!buttonLinkCollection &&
                    buttonLinkCollection
                      .slice(halfLength, buttonLinkCollection.length)
                      .map((button, idx) => (
                        <Button
                          key={idx + halfLength}
                          link={buttonLinkCollection[idx + halfLength].url}
                          value={button.title}
                        />
                      ))}
                </BottomRow>
              </div>
            ) : (
              <SingleRow>
                {' '}
                {!!buttonLinkCollection &&
                  buttonLinkCollection
                    .slice(0, buttonLinkCollection.length)
                    .map((button, idx) => (
                      <Button key={idx} link={buttonLinkCollection[idx].url} value={button.title} />
                    ))}
              </SingleRow>
            )}
          </ButtonWrapper>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
